.missionDescription {
  color: white;
  font-family: OpenSans;
  font-size: 22px;
  margin-top: 30px;
  max-width: 910px;
  text-align: center;
}
.missionContextTextContainer {
  height: 100%;
}
.missionDoThis {
  color: white;
  font-family: SummerLovingSolid-Regular;
  font-size: 120px;
  line-height: 67px;
}

.missionDoThisContent {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 140px;
}

.missionDoThisContentMia {
  color: white;
  font-family: SummerLovingSolid-Regular;
  font-size: 80px;
  line-height: 60px;
  text-align: center;
  margin-top: 100px;
}

.missionDoThisContentThumb {
}

.missionMargaret {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 70px;
  line-height: 46px;
  margin-top: 50px;
  max-width: 800px;
  text-align: center;
}

.missionMargaretName {
  color: white;
  font-family: OpenSans;
  font-size: 20px;
  line-height: 46px;
  margin-top: 10px;
  text-align: center;
}

.missionSubTitle {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;
  width: 100%;
}

.missionSubTitleEmoji {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 60px;
  margin-left: 15px;
}

.missionSubTitleText {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 120px;
}

.missionText {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 184px;
  line-height: 122px;
  margin-top: 80px;
  text-align: center;
}

/* mobile */
.missionMobileDescription {
  color: white;
  font-family: OpenSans;
  font-size: 16px;
  line-height: 22px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px;
  text-align: center;
}

.missionMobileDoThis {
  color: white;
  font-family: SummerLovingSolid-Regular;
  font-size: 100px;
  line-height: 67px;
  margin-top: 100px;
}

.missionMobileDoThisContent {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.missionMobileDoThisContentMia {
  color: white;
  font-family: SummerLovingSolid-Regular;
  font-size: 60px;
  line-height: 40px;
  text-align: center;
}

.missionMobileMargaret {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 50px;
  line-height: 36px;
  margin-top: 50px;
  text-align: center;
}

.missionMobileMargaretName {
  color: white;
  font-family: OpenSans;
  font-size: 16px;
  line-height: 46px;
  margin-top: 10px;
  text-align: center;
}

.missionMobileStoreButtons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.missionMobileStoreTitle {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -104px;
}

.missionMobileStoreTitleText {
  color: white;
  font-family: SummerLovingSolid-Regular;
  font-size: 50px;
  line-height: 36px;
  text-align: center;
}

.missionMobileStoreBee {
  margin-left: -243px;
  margin-top: -312px;
  width: 223px;
}

.missionMobileSubTitle {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.missionMobileSubTitleEmoji {
  height: 35px;
  margin-left: 10px;
  width: 35px;
}

.missionMobileSubTitleText {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 70px;
  line-height: 47px;
  text-align: center;
}

.missionMobileTitle {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 113px;
  line-height: 67px;
  margin-top: 70px;
  text-align: center;
}
