/* mobile */
.mobileBodyContent {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
}

.mobileHomeTitle {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
}

.mobileHomeTitleText {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 113px;
  line-height: 67px;
  margin: 0;
  text-align: center;
}

.mobileHomeBeeLeft {
    margin-left: -260px;
    margin-top: -130px;
    width: 48px;
}

.mobileHomeBeeRight {
    margin-left: 320px;
    margin-top: 30px;
    width: 48px;
}

.userBlock {
    background-color: #FFF;
    border-radius: 6px !important;
    padding: 20px;
}

.userLogo {
    color: #D8D8D8;
}

.userStatusBlock {
    display: flex;
    flex-direction: row;
}

.bodyInfoSummary {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
    margin-left: 20px;
}

.bodyInfoSummaryItem {
    align-items: center;
}

.bodyInfoSummaryItemValue {
    text-align: center;
    font-size: 24px;
    color: black;
    font-family: 'OpenSans-Semibold';
    margin-bottom: 0;
}

.bodyInfoSummaryItemTitle {
    text-align: center;
    font-size: 10px;
    color: black;
}

.bodyIntroName {
    color: #1D1D1D;
    font-size: 16px;
    margin-top: 10px;
    text-align: left;
    width: 100%;
}

.bodyIntroComment {
    color: black;
    font-size: 14px;
    margin-top: 10px;
    text-align: left;
    width: 100%;
}

.bodyMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.bodyMenuSocial {
    display: flex;
    flex-direction: row;
}

.bodyMenuShare {
    margin-right: 5px;
}

.bodyMenuActions {
    display: flex;
    flex-direction: row;

}

.bodyMenuFollow {
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 30px;
    border: #72C500 1px solid;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
}

.bodyMenuFollowText, .bodyMenuFollowText:hover {
    text-decoration: none;
    color: #72C500;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}

.bodyMenuAddEnviromates {
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 30px;
    border-radius: 6px;
    margin-left: 10px;
    background-color: #72C500;
    cursor: pointer;
    display: flex;
}

.bodyMenuAddEnviromateText {
    text-decoration: none;
    color: white;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}

.userLogoEmpty {
    color: #D8D8D8;
}

.bodyTapPanel {
    display: flex;
    overflow-y: scroll;
    scrollbar-width: none;
    overflow-x: hidden;
    flex: 1;
    width: 100%;
    align-items: center;
    flex-direction: column;
    /* margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px; */
}

/* .bodyTapPanel::-webkit-scrollbar {
    display: none;
} */

.bodyTapPanelScroll {
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.bodyTabPanelContent {
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }

.bodyTabPanelContentSection {
    align-items: center;
    margin-top: 10;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.bodyTabPanelContentSectionHeader {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.bodyTabPanelContentSectionHeaderTitle {
    color: black;
    font-weight: bold;
    font-size: 14px;
}

.bodyTabPanelContentSectionHeaderIcon {
    margin-left: 20px;
}

.bodyTabPanelContentSectionHeaderTitle {
    color: black;
    font-weight: bold;
    font-family: 'OpenSans-Semibold';
    font-size: 14px;
}

.bodyTabPanelContentSectionHeaderIcon {
    color: black;
    margin-left: 20px;
}

.bodyTabPanelContentSectionPanel {
    margin-top: 10px;
    width: 100%;
}

.bodyTabPanelContentEmptyTitle {
    color: black;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
}

.bodyTabPanelContentEmptyDescription {
    color: #1D1D1B;
    font-size: 14px;
    margin-top: 25px;
    text-align: center;
}

.bodyTabPanelContentEmpty {
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
}

.bodyTabPanelContentEmptyInfo {
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.activeTabTitle {
    font-family: 'OpenSans-Semibold';
    font-size: 12px;
    text-wrap-mode: nowrap;
    text-transform: none;
}

.tabTitle {
    font-family: 'OpenSans-Semibold';
    font-size: 12px;
    text-wrap-mode: nowrap;
    text-transform: none;
}



