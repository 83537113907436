/* global */
a,
a:hover {
  color: white;
}

input:focus,
textarea:focus {
  outline: none;
}

ol,
ul {
  margin-bottom: 0;
}

/* cookie */
.freeprivacypolicy-com---nb-simple {
  left: 0;
  max-width: 100%;
}

/* app */
.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
}

.appStoreModal {
  min-width: 1000px;
}

.appStoreModalBody {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appStoreModalBodyTitle {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 834px;
}

.appStoreModalBodyTitleText {
  color: #005FB0;
  font-family: SummerLoving-Regular;
  font-size: 96px;
  line-height: 60px;
  margin: 0;
  text-align: center;
}

.appStoreModalFooter {
  align-items: center;
  border-top: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 70px;
  margin-top: 30px;
}

.appStoreModalHeader {
  border-bottom: none;
  height: 100px;
}

.body {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
  
.bodyContent {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.bodyContentLeft {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.bodyStore {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 70px;
  margin-top: 80px;
}

.bodyStoreContent {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -60px;
}

.bodyStoreContentBarcode {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
}

.bodyStoreContentBarcodeImage {
    height: 124px;
    width: 124px;
}

.bodyStoreContentButtons {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bodyStoreContentDescription {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bodyStoreContentDescriptionText {
  color: white;
  font-family: OpenSans;
  font-size: 20px;
  line-height: 27px;
  margin: 0;
}

.bodyStoreTitle {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bodyStoreTitleText {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 154px;
  line-height: 98px;
  letter-spacing: 0;
  margin: 0;
}

.bodyStoreTitleBottomText {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 203px;
  line-height: 98px;
  letter-spacing: 0;
  margin: 0;
}

.bodyStoreTitleBeeLeft {
  margin-left: 584px;
  margin-top: 100px;
  width: 106px;
}

.bodyStoreTitleBeeRight {
  margin-left: -504px;
  margin-top: -240px;
  width: 106px;
}

.bodyVideo {
  background-color: rgb(0, 95, 176) !important; /*transparent !important;*/
  border-radius: 6px !important;
}

.bodyVideoBigButton {
    background-color: rgba(114, 197, 0, 0.9) !important;
    border-radius: 32px !important;
    border: none !important;
    font-size: 60px !important;
    height: 64px !important;
    line-height: 64px !important;
    margin-left: -32px !important;
    margin-top: -32px !important;
    width: 64px !important;
}

.bodyVideoControlBar {
    background-color: rgba(114, 197, 0, 0.8) !important;
}

.hr {
  color: white;
  margin: 50px 0 0 0;
  width: 100%;
}

.ol {
  color: white;
  font-family: OpenSans;
  font-size: 14px;
}

.page {
  /*overflow-x: hidden;*/
}

.seperator {
  flex: 1;
}

.video-react-video {
    border-radius: 6px !important;
}

/* mobile */
.modbileAppStoreModal {
}

.modbileAppStoreModalBody {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modbileAppStoreModalBodyTitle {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modbileAppStoreModalBodyTitleText {
  color: #005FB0;
  font-family: SummerLoving-Regular;
  font-size: 50px;
  line-height: 36px;
  margin: 0;
  text-align: center;
}

.modbileAppStoreModalFooter {
  align-items: center;
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}

.modbileAppStoreModalHeader {
  border-bottom: none;
}

.mobileOl {
  color: white;
  font-family: OpenSans;
  font-size: 13px;
}

.spinnerContainer {
  display: flex;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 300;
}

.spinner {
  margin: auto;
}

/* .spinner {
  alignItems: 'center',
  alignSelf: "center",
  height: 100,
  justifyContent: 'center',
  left: "50%",
  marginLeft: -50,
  marginTop: -50,
  position: 'absolute',
  top: "50%",
  width: 100,
},
 */

.Toastify__toast-container {
  max-width: 800px;
  width: auto !important;
}

.Toastify__toast-body {
  font-family: OpenSans;
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url(./res/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Semibold';
  src: local('OpenSans-Semibold'), url(./res/fonts/OpenSans-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: local('OpenSans-Bold'), url(./res/fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SummerLoving';
  src: local('SummerLoving'), url(./res/fonts/SummerLoving.otf) format('opentype');
}

@font-face {
  font-family: 'SummerLoving-Regular';
  src: local('SummerLoving-Regular'), url(./res/fonts/SummerLoving-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'SummerLovingSans-Regular';
  src: local('SummerLovingSans-Regular'), url(./res/fonts/SummerLovingSans-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'SummerLovingSolid-Regular';
  src: local('SummerLovingSolid-Regular'), url(./res/fonts/SummerLovingSolid-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'TimeBurner-Bold';
  src: local('TimeBurner-Bold'), url(./res/fonts/timeburnerbold.ttf) format('truetype');
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
