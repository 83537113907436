.chalBigBee {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 70px;
  width: 100%;
}

.chalBigBeeDescription {
  color: white;
  font-family: OpenSans;
  font-size: 20px;
  line-height: 27px;
}

.chalBigBeeLeft {
  align-items: center;
  display: flex;
  flex: 0.4;
  flex-direction: column;
  justify-content: center;
  margin-left: 35px;
  max-width: 534px;
}

.chalBigBeeLeftBeeHappyImage {
  margin-left: -400px;
  margin-top: -80px;
  width: 170px;
}

.chalBigBeeRight {
  align-items: center;
  display: flex;
  flex: 0.6;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: 35px;
  margin-right: 35px;
  max-width: 801px;
}

.chalBigBeeRightCol {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.chalBigBeeRightPercent {
  color: #72C500;
  font-family: SummerLovingSans-Regular;
  font-size: 70px;
  line-height: 79px;
  width: 100%;
}

.chalBigBeeRightPercentDescription {
  color: white;
  font-family: OpenSans-Bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.6px;
  width: 100%;
}

.chalBigBeeRightRow {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.chalBigBeeRightTitle {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.chalBigBeeRightTitleText {
  color: white;
  font-family: SummerLovingSans-Regular;
  font-size: 44px;
  line-height: 50px;
}

.chalBigBeeRow {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.chalBigBeeTitle {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -150px;
}

.chalBigBeeTitleText {
  color: white;
  font-family: SummerLovingSolid-Regular;
  font-size: 120px;
  line-height: 62px;
  text-align: center;
}

.chalBigBeeTitleTextSmall {
  color: white;
  font-family: SummerLovingSolid-Regular;
  font-size: 75px;
  line-height: 62px;
  text-align: center;
}

.chalParticipant {
  align-items: center;
  background-color: #D8D8D8;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  margin-right: 10px;
  width: 40px;
}

.chalParticipantIcon {
  color: darkgray;
  height: 25px;
  width: 25px;
}

.chalParticipantList {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20px;
}

.chalParticipantMore {
  align-items: center;
  background-color: #D8D8D8;
  border-radius: 20px;
  color: black;
  display: flex;
  flex-direction: row;
  font-family: OpenSans-Semibold;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  line-height: 19px;
  margin-right: 10px;
  width: 40px;
}

.chalParticipants {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 50px;
}

.chalParticipantsText {
  color: white;
  font-family: OpenSans-Bold;
  font-size: 20px;
  line-height: 27px;
}

.chalTitle {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chalTitleBee {
  margin-right: 90px;
  margin-top: -10px;
  width: 200px;
}

.chalTitleDescription {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}

.chalTitleDescriptionText {
  color: white;
  font-family: OpenSans;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  width: 678px;
}

.chalTitleText {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 233px;
  line-height: 118px;
  text-align: center;
  width: 100%;
}

.chalTitleTextSmall {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 184px;
  line-height: 103px;
  margin-top: -900px;
  text-align: center;
  width: 100%;
}

/* mobile */
.chalMobileBigBee {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  padding-left: 10px;
  padding-right: 10px;
}

.chalMobileBigBeeDescription {
  color: white;
  font-family: OpenSans;
  font-size: 16px;
  line-height: 22px;
  margin-top: 55px;
}

.chalMobileBigBeeBeeHappy {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: -20px;
  width: 100%;
}

.chalMobileBigBeeBeeHappyImage {
  margin-top: -80px;
  width: 110px;
}

.chalMobileBigBeeInfo {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.chalMobileBigBeeInfoPercent {
  color: #72C500;
  font-family: SummerLovingSans-Regular;
  font-size: 50px;
  line-height: 50px;
  width: 100px;
}

.chalMobileBigBeeInfoPercentDescription {
  color: white;
  flex: 1;
  font-family: OpenSans-Bold;
  font-size: 16px;
  line-height: 22px;
}

.chalMobileBigBeeQuickTitle {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 55px;
  width: 100%;
}

.chalMobileBigBeeQuickTitleText {
  color: white;
  font-family: SummerLovingSans-Regular;
  font-size: 34px;
  line-height: 38px;
  letter-spacing: 1.55px;
}

.chalMobileBigBeeTitle {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -80px;
}

.chalMobileBigBeeTitleText {
  color: white;
  font-family: SummerLovingSolid-Regular;
  font-size: 80px;
  line-height: 42px;
  text-align: center;
}

.chalMobileParticipants {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 55px;
}

.chalMobileParticipant {
  align-items: center;
  background-color: #D8D8D8;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  margin-right: 10px;
  width: 32px;
}

.chalMobileParticipantIcon {
  color: darkgray;
  height: 20px;
  width: 20px;
}

.chalMobileParticipantList {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.chalMobileParticipantMore {
  align-items: center;
  background-color: #D8D8D8;
  border-radius: 16px;
  color: black;
  display: flex;
  flex-direction: row;
  font-family: OpenSans-Semibold;
  font-size: 14px;
  height: 32px;
  justify-content: center;
  line-height: 19px;
  margin-right: 10px;
  width: 32px;
}

.chalMobileParticipantsText {
  color: white;
  font-family: OpenSans-Bold;
  font-size: 20px;
  line-height: 27px;
  width: 100%;
}

.chalMobileStoreButtons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.chalMobileStoreTitle {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -104px;
}

.chalMobileStoreTitleText {
  color: white;
  font-family: SummerLovingSolid-Regular;
  font-size: 50px;
  line-height: 36px;
  text-align: center;
}

.chalMobileStoreBee {
  margin-left: -243px;
  margin-top: -312px;
  width: 223px;
}

.chalMobileTitle {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.chalMobileTitleBee {
  margin-left: -100px;
  margin-top: -40px;
  width: 90px;
}

.chalMobileTitleText {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 113px;
  line-height: 67px;
  text-align: center;
  width: 100%;
}

.chalMobileTitleDescription {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.chalMobileTitleDescriptionText {
  color: white;
  font-family: OpenSans;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
