
/* const bodyHorizontalMargin = 10;
const mediaAspectRatio = 353 / 238; */

.postPanel {
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-width: 1px;
    border-color: #838383;
    border-radius: 6px;
    border-style: solid;
  }

.postPanel a {
    text-decoration: none;
}

.postPanel .bodyMedia {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

.postPanel .bodyMediaPhotos {
    align-items: center;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    border-top-color: #838383;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top-width: 1px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

.postPanel .bodyMediaPhotosEmpty {
    align-items: center;
    background-color: #D8D8D8;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    justify-content: center;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio;
    width: win.width - bodyHorizontalMargin * 2; */
  }

.postPanel .bodyMediaPhotosEmptyIcon {
    color: white;
  }

.postPanel .bodyMediaPhotoSlider {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio;
    width: win.width - bodyHorizontalMargin * 2; */
  }

.postPanel .bodyMediaPhotoSliderContent {
    align-items: center;
    justify-content: center;
  }

.postPanel .bodyMediaPhotoSliderPage {
    align-items: center;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio; */
    justify-content: center;
    /* width: win.width - bodyHorizontalMargin * 2; */
  }

.postPanel .bodyMediaPhotoSliderPageMedia {
    align-items: center;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    border-top-color: #838383;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top-width: 1px;
    justify-content: center;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio;
    width: win.width - bodyHorizontalMargin * 2; */
  }

.postPanel .bodyMediaPhotoSliderNavigator {
    flex-direction: row;
  }

.postPanel .bodyMediaPhotoSliderNavigatorActivDot {
    background-color: #72C500;
    border-radius: 6px;
    height: 10px;
    margin: 5px;
    width: 10px;
  }

.postPanel .bodyMediaPhotoSliderNavigatorBlackDot {
    background-color: darkgray;
    border-color: white;
    border-radius: 6px;
    border-width: 1px;
    height: 10px;
    margin: 5px;
    width: 10px;
  }

.postPanel .bodyMediaPhotoSliderNavigatorInactivDot {
    background-color: white;
    border-radius: 6px;
    height: 10px;
    margin: 5px;
    width: 10px;
  }

.postPanel .bodyMediaEmpty {
    align-items: center;
    background-color: #F1F1F1;
    flex-direction: column;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio; */
    justify-content: center;
    /* width: win.width - bodyHorizontalMargin * 2; */
  }

.postPanel .bodyMediaEmptySeperator {
    flex: 1;
  }

.postPanel .bodyMediaEmptyHeader {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    z-index: 100;
  }

.postPanel .bodyMediaEmptyHeaderUser {
    align-items: center;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

.postPanel .bodyMediaEmptyHeaderUserLogo {
    align-items: center;
    background-color: #D8D8D8;
    border-radius: 15px;
    height: 30px;
    justify-content: center;
    width: 30px;
  }

.postPanel .bodyMediaEmptyHeaderUserLogoIcon {
    border-radius: 15px;
    height: 30px;
    width: 30px;
  }

.postPanel .bodyMediaEmptyHeaderUserLogoEmpty {
    color: #D8D8D8;
  }

.postPanel .bodyMediaEmptyHeaderUserName {
    color: #1D1D1B;
    flex: 1;
    font-size: 14px;
    margin-left: 10px;
  }

.postPanel .bodyMediaEmptyHeaderTitleEllipsis {
    align-items: center;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;
  }

.postPanel .bodyMediaEmptyHeaderTitleEllipsisPressed {
    align-items: center;
    background-color: black;
    border-radius: 15px;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;
  }

.postPanel .bodyMediaEmptyHeaderTitleEllipsisIcon {
    color: white;
  }

.postPanel .bodyMediaEmptyHeaderTitleMenu {
    display: flex;
    align-items: flex-start;
    background-color: white;
    border-color: #838383;
    border-radius: 6px;
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 150;
  }

.postPanel .bodyMediaEmptyHeaderTitleMenuItem {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

.postPanel .bodyMediaEmptyHeaderTitleMenuItemIcon {
    color: #1D1D1B;
    height: 20px;
    width: 20px;
  }

.postPanel .bodyMediaEmptyHeaderTitleMenuItemText {
    color: #1D1D1B;
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
  }

.postPanel .bodyMediaEmptyContent {
    align-items: center;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

.postPanel .bodyMediaEmptyContentIcon {
    color: #D8D8D8;
  }

.postPanel .bodyMediaEmptyContentLeft {
    align-items: flex-start;
    flex: 1;
    justify-content: center;
  }

.postPanel .bodyMediaEmptyContentCenter {
    align-items: center;
    flex: 1;
    height: 100%;
    justify-content: center;
  }

.postPanel .bodyMediaEmptyContentCenterPlay {
    align-items: center;
    background-color: white;
    border-radius: 25px;
    justify-content: center;
  }

.postPanel .bodyMediaEmptyContentCenterPlayIcon {
    color: #72C500;
  }

.postPanel .bodyMediaEmptyContentCenterPlayBlackIcon {
    color: darkgray;
  }

.postPanel .bodyMediaEmptyContentCenterSlideIcon {
    color: white;
  }

.postPanel .bodyMediaEmptyContentRight {
    align-items: flex-end;
    flex: 1;
    justify-content: center;
  }

.postPanel .bodyMediaEmptyFooter {
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
    padding-right: 10px;
    width: 100%;
  }

.postPanel .bodyMediaEmptyFooterContent {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

.postPanel .bodyMediaEmptyFooterLeft {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

.postPanel .bodyMediaEmptyFooterLeftItem {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-left: 10px;
  }

.postPanel .bodyMediaEmptyFooterLeftItemIcon {
    color: #1D1D1B;
  }

.postPanel .bodyMediaEmptyFooterLeftItemText {
    color: #1D1D1B;
    font-weight: 500;
    font-size: 20px;
    margin-left: 5px;
  }

.postPanel .bodyMediaEmptyFooterCenter {
    align-items: center;
    flex: 1;
    justify-content: center;
  }

.postPanel .bodyMediaEmptyFooterRight {
    align-items: flex-end;
    flex-direction: row;
    justify-content: center;
  }

.postPanel .bodyMediaEmptyFooterDots {
    align-items: center;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

.postPanel .bodyMediaImage {
    align-items: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    flex-direction: column;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio; */
    justify-content: center;
    width: 100%;
  }

.postPanel .bodyMediaImageControl {
    align-items: center;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio; */
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

.postPanel .bodyMediaImageControlHeaderTitle {
    flex: 1;
  }

.postPanel .bodyMediaImageControlHeaderTitleText {
    color: white;
    flex: 1;
    font-weight: bold;
    /* fontFamily: OpenSans-Bold; */
    font-size: 16px;
    width: 100%;
  }

.postPanel .bodyMediaImageControlHeaderTitleMenuIcon {
    color: white;
  }

.postPanel .bodyMediaImageControlHeaderUserLogoIcon {
    border-color: white;
    border-radius: 15px;
    border-width: 1px;
    height: 30px;
    width: 30px;
  }

.postPanel .bodyMediaImageControHeaderUserLogoEmpty {
    background-color: white;
    border-radius: 15px;
    height: 30px;
    width: 30px;
  }

.postPanel .bodyMediaImageControHeaderUserLogoEmptyIcon {
    border-color: white;
    border-radius: 15px;
    border-width: 1px;
    color: #D8D8D8;
  }

.postPanel .bodyMediaImageControlHeaderUserName {
    align-items: center;
    flex: 1;
    justify-content: center;
    margin-left: 10px;
  }

.postPanel .bodyMediaImageControlHeaderUserNameText {
    color: white;
    font-size: 14px;
    width: 100%;
  }

.postPanel .bodyMediaImageControlFooterLeftItemIcon {
    color: white;
  }

.postPanel .bodyMediaImageControlFooterLeftItemText {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-left: 5px;
  }

.postPanel .bodyMediaImageControlFooterPercentText {
    color: white;
    font-weight: 500;
    font-size: 24px;
    margin-left: 5px;
  }

.postPanel .bodyMediaImageControlFooterPercentTitleText {
    color: white;
    font-weight: 500;
    font-size: 12px;
    margin-left: 5px;
  }

.postPanel .bodyMediaImageControlFooterFeedback {
    align-items: center;
    background-color: white;
    border-radius: 12px;
    height: 24px;
    justify-content: center;
    width: 24px;
  }

.postPanel .bodyMediaImageControlFooterFeedbackIcon {
    color: #009EE2;
  }

.postPanel .bodyInfo {
    align-items: center;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    flex-direction: row;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    width: 100%;
  }

.postPanel .bodySummaryText {
    color: black;
    font-size: 14px;
    width: 100%;
  }

.postPanel .bodySummaryTextContinue {
    color: #72C500;
    font-size: 14px;
    text-align: left;
    text-decoration: underline;
    width: 100%;
  }

.postPanel .bodyInfoPost {
    color: #838383;
    font-size: 12px;
    text-align: left;
    width: 100%;
  }

.postPanel .bodyComment {
    display: flex;
    align-items: center;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    justify-content: center;
    flex-direction: row;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

.postPanel .bodyCommentEmpty {
    align-items: center;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    justify-content: center;
    flex-direction: row;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

.postPanel .panelEnd {
    display: flex;
    flex-direction: row;
    margin: 10px;
    border-bottom-color: #838383;
    border-bottom-width: 1px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

.postPanel .bodyCommentLogoEmpty {
    color: #D8D8D8;
  }

.postPanel .bodyCommentLogo {
    display: flex;
    align-items: center;
    align-self: flex-start;
    background-color: #D8D8D8;
    border-radius: 16px;
    height: 32px;
    justify-content: center;
    width: 32px;
  }

.postPanel .bodyCommentLogoIcon {
    border-radius: 16px;
    height: 32px;
    width: 32px;
  }

.postPanel .bodyCommentLogoHeart {
    color: #FF4600;
    position: absolute;
  }

.postPanel .bodyCommentContent {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    width: 100%;
  }

.postPanel .bodyCommentContentRow {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

.postPanel .bodyCommentTitle {
    align-items: center;
    flex: 1;
    justify-content: center;
  }

.postPanel .bodyCommentTitleText {
    color: black;
    flex: 1;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
  }

.postPanel .bodyCommentCreator {
    align-items: center;
    justify-content: center;
  }

.postPanel .bodyCommentCreatorText {
    color: black;
    flex: 1;
    font-weight: 500;
    font-size: 12px;
  }

.postPanel .bodyCommentBody {
    align-items: center;
    flex: 1;
    justify-content: center;
  }

.postPanel .bodyCommentBodyText {
    color: black;
    flex: 1;
    font-size: 12px;
    width: 100%;
  }

.postPanel .bodyCommentLike {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 40px;
  }

.postPanel .bodyCommentLikeIcon {
    color: #838383;
  }

.postPanel .bodyCommentTimeText {
    color: #979797;
    font-size: 11px;
  }

.postPanel .bodyCommentDot {
    background-color: #979797;
    border-radius: 1px;
    height: 2px;
    margin-left: 5px;
    margin-right: 5px;
    width: 2px;
  }

.postPanel .bodyCommentReply {
    align-items: center;
    justify-content: center;
  }

.postPanel .bodyCommentDash {
    background-color: #979797;
    height: 1px;
    margin-right: 5px;
    margin-top: 1px;
    width: 25px;
  }

.postPanel .bodyCommentCommenting {
    align-items: center;
    flex: 1;
    justify-content: center;
    width: 100%;
  }

.postPanel .bodyCommentCommentingText {
    color: #979797;
    font-size: 11px;
    width: 100%;
  }

.postPanel .commentActionSheet {
    border-top-color: #B4B4B4;
    border-top-width: 1px;
    flex-direction: column;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

.postPanel .commentActionSheetRow {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
  }

.postPanel .commentActionSheetHearts {
    align-items: center;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
  }

.postPanel .commentActionSheetHeart {
    height: 24px;
    margin-right: 5px;
    width: 24px;
  }

.postPanel .commentActionSheetHeartIcon {
    color: #FF4600;
    left: 0;
    position: absolute;
    top: 0;
  }

.postPanel .commentActionSheetHeartIconDisabled {
    color: #838383;
    left: 0;
    position: absolute;
    top: 0;
  }

.postPanel .commentActionSheetHeartText {
    color: white;
    font-weight: bold;
    font-size: 10px;
    height: 24px;
    left: 0;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 24px;
  }

.postPanel .commentActionSheetClose {
    align-items: flex-end;
    height: 35px;
    justify-content: center;
    margin-right: -5px;
    width: 25px;
  }

.postPanel .commentActionSheetCloseIcon {
    color: #979797;
  }

.postPanel .commentActionSheetLogoEmpty {
    color: #D8D8D8;
    background-color: #FFFFFF;
  }

.postPanel .commentActionSheetLogo {
    align-items: center;
    background-color: #D8D8D8;
    border-radius: 15px;
    height: 30px;
    justify-content: center;
    width: 30px;
  }

.postPanel .commentActionSheetLogoIcon {
    border-radius: 15px;
    height: 30px;
    width: 30px;
  }

.postPanel .commentActionSheetInput {
    align-items: center;
    border-color: #838383;
    border-radius: 6px;
    border-width: 1px;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

.postPanel .commentActionSheetInputText {
    color: #1D1D1B;
    flex: 1;
    font-size: 14px;
  }

.postPanel .commentActionSheetInputHeart {
    align-items: center;
    align-self: flex-end;
    height: 24px;
    justify-content: center;
    margin-left: 10px;
    width: 24px;
  }

.postPanel .commentActionSheetInputSend {
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    margin-left: 10px;
  }

.postPanel .commentActionSheetInputSendText {
    color: rgba(114,197,0,0.5);
    font-size: 14px;
  }

.postPanel .seperator {
    flex: 1;
  }

.postPanel .bodyCrowdfund {
    align-items: center;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    justify-content: center;
    width: 100%;
  }

.postPanel .bodyCrowdfundProgress {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #838383;
    width: 100%;
    height: 6px;
  }

.postPanel .bodyCrowdfundRow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

.postPanel .txtCrowdfund {
    color: black;
    font-weight: 500;
    font-size: 14px;
  }

.postPanel .imgCrowdfundHeart {
    width: 21px;
    height: 18px;
    margin-left: 8px;
    margin-right: 8px;
  }

.postPanel .txtCrowdfundHeart {
    color: black;
    font-weight: 500;
    font-size: 24px;
  }

.postPanel .txtCrowdfundCurrency {
    color: #838383;
    font-size: 12px;
  }

.postPanel .btnCrowdfund {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-color: #838383;
    border-bottom-width: 1px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    background-color: #72C500;
    width: 100%;
    height: 46px;
    margin-top: 20px;
  }


.postPanel .btnCrowdfundText {
    color: white;
    font-weight: 500;
    font-size: 14px;
}
