.footer {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: flex-start;
  width: 100%;
}

.footerIcon {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 28px;
}

.footerIconImage {
  height: 24px;
  width: 24px;
}

.footerIconImageThread {
  height: 30px;
  width: 30px;
}

.footerMenu {
  cursor: pointer;
  margin-right: 40px;
}

.footerMenuText {
  color: black;
  font-family: OpenSans;
  font-size: 18px;
  text-decoration: none;
}

/* mobile */
.mobileFooter {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 30px;
  width: 100%;
}

.mobileFooterMenus {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
}

.mobileFooterMenu {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mobileFooterMenuText {
  color: black;
  font-family: OpenSans;
  font-size: 12px;
  text-decoration: none;
} 

.mobileFooterSocial {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.mobileFooterSocialIcon {
  height: 20px;
  width: 20px;
}
