/* global */
.dropdown-menu {
}
.dropdown-item {
  padding-left: 25px;
}

/* app */
main {
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100vw;
}

.adminContent {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 8px 10px;
}

.adminContentTitle {
  font-size: 24px;
}

.adminContentTextArea {
  border: none;
  font-family: OpenSans;
  font-size: 14px;
  resize: none;
  width: 100%;
}

.adminDropDownIemChecked::before {
  position: absolute;
  left: 5px;
  content: '✓ ';
  font-weight: 800;
}

.adminContentListItem {
  align-items: center;
  background-color: white;
  border-color: #838383;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5px;
  padding: 0px 5px;
  width: 100%;
}

.adminContentListIteamTextArea {
  border: none;
  font-family: OpenSans;
  font-size: 14px;
  resize: none;
  width: 100%;
}
