.advCashback {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.advCashbackContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 80px 0 0 0;
  width: 330px;
}

.advCashbackTitle {
  color: white;
  font-family: SummerLovingSans-Regular;
  font-size: 60px;
  line-height: 50px;
  margin: 0;
}

.advCashbackDescription {
  color: white;
  font-family: OpenSans-Semibold;
  font-size: 20px;
  line-height: 27px;
  margin: 30px 0 0 0;
  max-width: 380px;
}

.advCashbackPhoneImage {
  margin-left: 10px;
  margin-right: -40px;
}

.advCustomers {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -198px;
  width: 100%;
}

.advCustomersContent {
  align-items: flex-start;
  display: flex;
  flex: 0.4;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: -100px;
  padding: 50px 0 0 0;
  width: 330px;
}

.advCustomersPhone {
  align-items: center;
  display: flex;
  flex: 0.8;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: -200px;
  margin-right: 30px;
}

.advCustomersPhoneImage {
  position: relative;
  right: 0px;
  top: 0px;
}

.advManager {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -50px;
  width: 100%;
}

.advManagerContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: -60px;
  padding: 140px 0 0 0;
  width: 330px;
}

.advManagerPhoneImage {
  margin-left: -50px;
}

.advReachback {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -140px;
  width: 100%;
}

.advReachbackContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: -60px;
  padding: 140px 0 0 0;
  width: 330px;
}

.advReachbackPhoneImage {
  margin-left: -50px;
}

.advSurvey {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -100px;
  margin-top: -280px;
  width: 100%;
}

.advSurveyContent {
  align-items: flex-start;
  display: flex;
  flex: 0.4;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: -100px;
  padding: 150px 0 0 0;
  width: 330px;
}

.advSurveyPhone {
  align-items: center;
  display: flex;
  flex: 0.8;
  flex-direction: row;
  justify-content: flex-end;
}

.advSurveyPhoneImage {
  position: relative;
  right: 0px;
  top: 0px;
}

.advWidget {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -220px;
  width: 100%;
}

.advWidgetContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: -60px;
  padding: 100px 0 0 0;
  width: 330px;
}

.advWidgetPhoneImage {
  margin-left: -50px;
}

.advWinkback {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -280px;
  width: 100%;
}

.advWinkbackContent {
  align-items: flex-start;
  display: flex;
  flex: 0.4;
  flex-direction: column;
  justify-content: flex-start;
  padding: 150px 0 0 0;
  width: 330px;
}

.advWinkbackPhone {
  align-items: center;
  display: flex;
  flex: 0.8;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: -190px;
  margin-right: -100px;
}

.advWinkbackPhoneImage {
  position: relative;
  right: 0px;
  top: 0px;
}

.advStore {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 264px;
}

.advStoreButtons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
}

.advStoreTitle {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -104px;
}

.advStoreTitleText {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 90px;
  line-height: 67px;
  letter-spacing: 0;
  margin: 0;
}

.advStoreBee {
  margin-left: -243px;
  margin-top: -312px;
  width: 223px;
}

/* mobile */
.advMobileInfo {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}

.advMobileInfoTitle {
  color: white;
  font-family: SummerLovingSans-Regular;
  font-size: 50px;
  line-height: 50px;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.advMobileInfoDescription {
  color: white;
  font-family: OpenSans-Bold;
  font-size: 16px;
  line-height: 22px;
  margin: 20px 0 0 0;
  padding-left: 10px;
  padding-right: 10px;
}

.advMobileInfoImage {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}
