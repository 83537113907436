.loginButton {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}

.loginInput {
  border-color: #838383;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  font-family: OpenSans;
  font-size: 14px;
  height: 40px;
  line-height: 19px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.loginInputWrong {
  border-color: red;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  font-family: OpenSans;
  font-size: 14px;
  height: 40px;
  line-height: 19px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.loginTitle {
  color: white;
  font-family: SummerLovingSans-Regular;
  font-size: 60px;
  line-height: 50px;
  margin: 0;
}
