.cookieBody {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.cookieContent {
  align-items: center;
  display: flex;
  flex: 0.6;
  flex-direction: row;
  justify-content: center;
  padding-left: 45px;
}

.cookieContentBody {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 852px;
}

.cookieTextDescription {
  color: white;
  font-family: OpenSans;
  font-size: 14px;
  margin-top: 20px;
}

.cookieTextSubTitle {
  color: white;
  font-family: OpenSans-Bold;
  font-size: 16px;
  line-height: 22px;
  margin-top: 35px;
}

.cookieTextTitle {
  color: white;
  font-family: SummerLovingSans-Regular;
  font-size: 60px;
  line-height: 50px;
  margin-top: 50px;
}

.cookieTitle {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 184px;
  line-height: 122px;
  margin-top: 80px;
  text-align: center;
  width: 100%;
}

.cookieTextDescriptionLink {
  color: white;
  display: block;
  font-family: OpenSans;
  font-size: 14px;
}

/* mobile */
.cookieMobileBody {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.cookieMobileDescription {
  color: white;
  font-family: OpenSans;
  font-size: 13px;
  line-height: 17px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.cookieMobileDescriptionLink {
  color: white;
  display: block;
  font-family: OpenSans;
  font-size: 13px;
  line-height: 17px;
}

.cookieMobileStoreButtons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.cookieMobileStoreTitle {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -104px;
}

.cookieMobileStoreTitleText {
  color: white;
  font-family: SummerLovingSolid-Regular;
  font-size: 50px;
  line-height: 36px;
  text-align: center;
}

.cookieMobileStoreBee {
  margin-left: -243px;
  margin-top: -312px;
  width: 223px;
}

.cookieMobileSubTitle {
  color: white;
  font-family: SummerLovingSans-Regular;
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
}

.cookieMobileSubTitleSub {
  color: white;
  font-family: OpenSans-Bold;
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
}

.cookieMobileTitle {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 113px;
  line-height: 67px;
  margin-top: 70px;
  text-align: center;
  width: 100%;
}
