.termsBody {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.termsContent {
  align-items: center;
  display: flex;
  flex: 0.6;
  flex-direction: row;
  justify-content: center;
  padding-left: 45px;
}

.termsContentBody {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 852px;
}

.termsTextDescription {
  color: white;
  font-family: OpenSans;
  font-size: 14px;
  margin-top: 20px;
}

.termsTextSubTitle {
  color: white;
  font-family: OpenSans-Bold;
  font-size: 16px;
  line-height: 22px;
  margin-top: 35px;
}

.termsTextTitle {
  color: white;
  font-family: SummerLovingSans-Regular;
  font-size: 60px;
  line-height: 50px;
  margin-top: 50px;
}

.termsTitle {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 184px;
  line-height: 122px;
  margin-top: 80px;
  text-align: center;
}

/* mobile */
.termsMobileBody {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.termsMobileDescription {
  color: white;
  font-family: OpenSans;
  font-size: 13px;
  line-height: 17px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.termsMobileStoreButtons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.termsMobileStoreTitle {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -104px;
}

.termsMobileStoreTitleText {
  color: white;
  font-family: SummerLovingSolid-Regular;
  font-size: 50px;
  line-height: 36px;
  text-align: center;
}

.termsMobileStoreBee {
  margin-left: -243px;
  margin-top: -312px;
  width: 223px;
}

.termsMobileSubTitle {
  color: white;
  font-family: SummerLovingSans-Regular;
  font-size: 40px;
  line-height: 1;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
}

.termsMobileSubTitleSub {
  color: white;
  font-family: OpenSans-Bold;
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
}

.termsMobileTitle {
  color: white;
  font-family: SummerLoving-Regular;
  font-size: 113px;
  line-height: 67px;
  margin-top: 70px;
  text-align: center;
}
