.header {
  align-items: center;
  background-color: rgb(0, 95, 176);
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: flex-start;
  overflow: hidden;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 100;
}

.browser {
  align-items: center;
  background-color: white;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 38px;
  justify-content: center;
  margin-right: 35px;
  padding: 0px 10px 0px 10px;
  z-index: 100;
}

.browserLogo {
  height: 18px;
  width: 18px;
}

.browserText {
  color: #72C500;
  font-family: OpenSans-Semibold;
  font-size: 14px;
  margin-left: 6px;
  text-decoration: none;
}

.headerLogo {
  align-items: center;
  background-color: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  margin-left: 35px;
  width: 60px;
  z-index: 100;
}

.headerLogoImage {
  height: 58px;
  width: 58px;
}

.headerMenu {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  margin-right: 40px;
  z-index: 100;
}

.headerMenuSelected {
  align-items: center;
  border-bottom-color: #72C500;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  margin-right: 40px;
  z-index: 100;
}

.headerMenuText {
  color: white;
  font-family: OpenSans;
  font-size: 18px;
  text-decoration: none;
  z-index: 150;
}

.headerTitle {
  color: white;
  font-family: TimeBurner-Bold;
  font-size: 43px;
  margin-left: 20px;
  z-index: 100;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* mobile */
.mobileHeader {
  align-items: center;
  background-color: rgb(0, 95, 176);
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 100;
}

.mobileHeaderLogo {
  align-items: center;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  height: 30px;
  justify-content: center;
  margin-left: 10px;
  width: 30px;
  z-index: 100;
}

.mobileHeaderLogoImage {
  height: 28px;
  width: 28px;
}

.mobileHeaderMenuButton {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  z-index: 100;
}

.mobileHeaderMenuButtonIcon {
  color: white;
  height: 22px;
  width: 22px;
}

.mobileHeaderPopup {
  align-items: flex-start;
  background-color: rgb(0, 95, 176);
  border-color: white;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 42px;
  z-index: 150;
}

.mobileHeaderPopupMenu {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
  margin-left: 25px;
  margin-right: 20px;
  z-index: 150;
}

.mobileHeaderPopupMenuSelected {
  align-items: center;
  border-bottom-color: #72C500;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
  margin-left: 25px;
  margin-right: 20px;
  z-index: 150;
}

.mobileHeaderPopupMenuText {
  color: white;
  font-family: OpenSans;
  font-size: 16px;
  text-decoration: none;
  width: 100%;
  z-index: 150;
}

.mobileHeaderPopupMenubrowser {
  align-items: center;
  background-color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 38px;
  justify-content: center;
  width: 100%;
  z-index: 150;
}

.mobileHeaderPopupMenubrowserLogo {
  height: 15px;
  margin-left: 5px;
  width: 15px;
}

.mobileHeaderPopupMenubrowserText {
  color: #72C500;
  flex: 1;
  font-family: OpenSans-Semibold;
  font-size: 14px;
  margin-left: 5px;
  text-decoration: none;
}

.mobileHeaderPopupMenuMiscText {
  color: black;
  font-family: OpenSans;
  font-size: 16px;
  text-decoration: none;
  width: 100%;
  z-index: 150;
}

.mobileHeaderTitle {
  color: white;
  font-family: TimeBurner-Bold;
  font-size: 20px;
  margin-left: 10px;
  z-index: 100;
  margin-top: 0px;
  margin-bottom: 0px;
}

