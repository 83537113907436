.PaypalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  width: 100%;
}

.PaypalText {
  font-family: 'PayPal-Sans Small', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}
