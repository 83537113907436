.panel {
    display: flex;
    align-items: center;
    border-radius: 6px;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 10px;
    border-width: 1px;
    border-color: #838383;
    width: 100%;
    border-style:outset;
}

.panel a {
    text-decoration: none;
}

.panelPress {
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.bodyMedia {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    z-index: 150;
    position: relative;
}

.bodyMedia {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    z-index: 150;
}

.bodyMediaPhotos {
    align-items: center;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    border-top-color: #838383;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top-width: 1px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.bodyMediaPhotosEmpty {
    display: flex;
    align-items: center;
    background-color: #D8D8D8;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    justify-content: center;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio;
    width: win.width - bodyHorizontalMargin * 2; */
}

.bodyMediaPhotosEmptyIcon {
    color: white;
}

.bodyMediaPhotoSlider {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio;
    width: win.width - bodyHorizontalMargin * 2; */
}

.bodyMediaPhotoSliderContent {
    align-items: center;
    justify-content: center;
}

.bodyMediaPhotoSliderPage {
    align-items: center;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio; */
    /* width: win.width - bodyHorizontalMargin * 2; */
    justify-content: center;
}

.bodyMediaPhotoSliderPageMedia {
    align-items: center;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    border-top-color: #838383;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top-width: 1px;
    justify-content: center;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio;
    width: win.width - bodyHorizontalMargin * 2; */
}

.bodyMediaPhotoSliderNavigator {
    flex-direction: row;
}

.bodyMediaPhotoSliderNavigatorActivDot {
    background-color: #72C500;
    border-radius: 6px;
    height: 10px;
    margin: 5px;
    width: 10px;
}

.bodyMediaPhotoSliderNavigatorBlackDot {
    background-color: darkgray;
    border-color: white;
    border-radius: 6px;
    border-width: 1px;
    height: 10px;
    margin: 5px;
    width: 10px;
}

.bodyMediaPhotoSliderNavigatorInactivDot {
    background-color: white;
    border-radius: 6px;
    height: 10px;
    margin: 5px;
    width: 10px;
}

.bodyMediaEmpty {
    align-items: center;
    background-color: #F1F1F1;
    flex-direction: column;
    justify-content: center;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio;
    width: win.width - bodyHorizontalMargin * 2; */
}

.bodyMediaEmptySeperator {
    flex: 1;
}

.bodyMediaEmptyHeader {
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    z-index: 100;
}

.bodyMediaEmptyHeaderTitle {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: 150;
}

.bodyMediaEmptyHeaderTitleText {
    color: #1D1D1B;
    flex: 1;
    font-weight: bold;
    font-size: 16px;
}

.bodyMediaEmptyHeaderTitleEllipsis {
    align-items: center;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;
}

.bodyMediaEmptyHeaderTitleEllipsisPressed {
    align-items: center;
    background-color: black;
    border-radius: 15px;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;
}

.bodyMediaEmptyHeaderTitleEllipsisIcon {
    color: white;
}

.bodyMediaEmptyHeaderTitleMenu {
    align-items: center;
    background-color: white;
    border-color: #838383;
    border-radius: 6px;
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    position: absolute;
    right: 0px;
    top: 30px;
    z-index: 150;
}

.bodyMediaEmptyHeaderTitleMenuItem {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.bodyMediaEmptyHeaderTitleMenuItemIcon {
    color: #1D1D1B;
    height: 20px;
    width: 20px;
}

.bodyMediaEmptyHeaderTitleMenuItemText {
    color: #1D1D1B;
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
}

.bodyMediaEmptyHeaderUser {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5px;
    width: 100%;
}

.bodyMediaEmptyHeaderUserLogo {
    align-items: center;
    background-color: #D8D8D8;
    border-radius: 15px;
    height: 30px;
    justify-content: center;
    width: 30px;
}

.bodyMediaEmptyHeaderUserLogoIcon {
    border-radius: 15px;
    height: 30px;
    width: 30px;
}

.bodyMediaEmptyHeaderUserLogoEmpty {
    color: #D8D8D8;
}

.bodyMediaEmptyHeaderUserName {
    color: #1D1D1B;
    flex: 1;
    font-size: 14px;
    margin-left: 10px;
}

.bodyMediaEmptyContent {
    align-items: center;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.bodyMediaEmptyContentIcon {
    color: #D8D8D8;
}

.bodyMediaEmptyContentLeft {
    align-items: flex-start;
    flex: 1;
    justify-content: center;
}

.bodyMediaEmptyContentCenter {
    align-items: center;
    flex: 1;
    height: 100%;
    justify-content: center;
}

.bodyMediaEmptyContentCenterPlay {
    align-items: center;
    background-color: white;
    border-radius: 25px;
    justify-content: center;
}

.bodyMediaEmptyContentCenterPlayIcon {
    color: #72C500;
}

.bodyMediaEmptyContentCenterPlayBlackIcon {
    color: darkgray;
}

.bodyMediaEmptyContentCenterSlideIcon {
    color: white;
}

.bodyMediaEmptyContentRight {
    align-items: flex-end;
    flex: 1;
    justify-content: center;
}

.bodyMediaEmptyFooter {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
    padding-right: 10px;
    width: 100%;
}

.bodyMediaEmptyFooterContent {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.bodyMediaEmptyFooterLeft {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.bodyMediaEmptyFooterLeftItem {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-left: 10px;
}

.bodyMediaEmptyFooterRightItem {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-right: 10px;
}

.bodyMediaEmptyFooterLeftItemIcon {
    color: #1D1D1B;
}

.bodyMediaEmptyFooterLeftItemText {
    color: #1D1D1B;
    font-weight: 500;
    font-size: 20px;
    margin-left: 5px;
}

.bodyMediaEmptyFooterCenter {
    align-items: center;
    flex: 1;
    justify-content: center;
}

.bodyMediaEmptyFooterRight {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: center;
    margin-right: 10px;
}

.bodyMediaEmptyFooterDots {
    align-items: center;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.bodyMediaImage {
    align-items: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    flex-direction: column;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio;*/
    justify-content: center;
    width: 100%;
}

.bodyMediaImageControl {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: (win.width - bodyHorizontalMargin * 2) / mediaAspectRatio; */
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.bodyMediaImageControlHeaderTitle {
    flex: 1;
    text-decoration: none;
}

.bodyMediaImageControlHeaderTitleText {
    color: white;
    flex: 1;
    font-weight: bold;
    font-size: 16x;
    width: 100%;
}

.bodyMediaImageControlHeaderTitleMenuIcon {
    color: white;
}

.bodyMediaImageControlHeaderUserLogoIcon {
    border-color: white;
    border-radius: 15px;
    border-width: 1px;
    height: 30px;
    width: 30px;
}

.bodyMediaImageControHeaderUserLogoEmpty {
    border-color: white;
    border-radius: 15px;
    border-width: 1px;
    color: #D8D8D8;
}

.bodyMediaImageControlHeaderUserName {
    align-items: center;
    flex: 1;
    justify-content: center;
    margin-left: 10px;
}

.bodyMediaImageControlHeaderUserNameText {
    color: white;
    font-size: 14px;
    width: 100%;
}

.bodyMediaImageControlFooterLeftItemIcon {
    color: white;
}

.bodyMediaImageControlFooterLeftItemText {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-left: 5px;
}

.bodyMediaImageControlFooterPercentText {
    color: white;
    font-weight: 500;
    font-size: 24px;
    margin-left: 5px;
}

.bodyMediaImageControlFooterPercentTitleText {
    color: white;
    font-weight: 500;
    font-size: 12px;
    margin-left: 5px;
}

.bodyMediaImageControlFooterFeedback {
    align-items: center;
    background-color: white;
    border-radius: 12px;
    height: 24px;
    justify-content: center;
    width: 24px;
}

.bodyMediaImageControlFooterFeedbackIcon {
    color: #009EE2;
}

.bodyMediaImageControlFooterReview {
    align-items: center;
    background-color: #FFCB18;
    border-radius: 12px;
    height: 24px;
    justify-content: center;
    width: 24px;
}

.bodyMediaImageControlFooterReviewIcon {
    color: white;
}

.bodyProgressBar {
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    flex-direction: row;
    height: 6px;
    width: 100%;
}

.bodyProgressBarActive {
    background-color: #72C500;
}

.bodyProgressBarInactive {
    background-color: #D8D8D8;
    flex: 1;
}

.bodyInfo {
    display: flex;
    align-items: center;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;
}

.bodyInfoItemFund {
    align-items: flex-start;
    flex: 1;
    justify-content: center;
}

.bodyInfoItemBackers {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.bodyInfoItemText {
    color: black;
    font-weight: 500;
    font-size: 14px;
}

.bodyInfoItemFollowWrap {
    display: flex;
    align-items: flex-end;
    flex: 1;
    justify-content: flex-end;
}

.bodyInfoFollow {
    align-items: center;
    border-color: #72C500;
    border-radius: 6px;
    border-width: 1px;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
}

.bodyInfoFollowText {
    color: #72C500;
    font-weight: 500;
    font-size: 14px;
}

.bodyInfoFollowInactive {
    align-items: center;
    justify-content: center;
}

.bodyInfoFollowIactiveText {
    color: black;
    font-weight: 500;
    font-size: 14px;
}

.bodySummaryText {
    color: black;
    font-size: 14px;
    width: 100%;
}

.bodyTeamTitle {
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
}

.bodyTeamTitleText {
    color: #838383;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
}

.bodyTeamMembers {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    width: 100%;
}

.bodyTeamMember {
    align-items: center;
    justify-content: flex-start;
    margin-right: 5px;
}

.bodyTeamMemberLogo {
align-items: center;
background-color: #D8D8D8;
border-radius: 15px;
height: 30px;
justify-content: center;
width: 30px;
}

.bodyTeamMemberLogoIcon {
    border-radius: 15px;
    height: 30px;
    width: 30px;
}

.bodyTeamMemberLogoEmpty {
    color: #D8D8D8;
}

.bodyTeamMemberCount {
    align-items: center;
    background-color: #D8D8D8;
    border-radius: 15px;
    height: 30px;
    justify-content: center;
    width: 30px;
}

.bodyTeamMemberCountText {
    color: black;
    font-weight: 500;
    font-size: 14px;
}

.bodyBack {
    display: flex;
    align-items: center;
    background-color: #72C500;
    border-bottom-color: #838383;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-width: 1px;
    border-left-color: #838383;
    border-left-width: 1px;
    border-right-color: #838383;
    border-right-width: 1px;
    height: 45px;
    justify-content: center;
    margin-bottom: -10px;
    width: 100%;
}

.bodyBackText {
    color: white;
    font-weight: 500;
    font-size: 14px;
}






