/* desktop */
.btnAppStoreLink {
  text-decoration: none;
}

.btnAppStore {
  align-items: center;
  background-color: black;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: flex-start;
  padding: 0 14px;
  width: 200px;
}

.btnAppStoreIcon {
  color: white;
  height: 30px;
  width: 30px;
}

.btnAppStoreTitle {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.btnAppStoreTitleText {
  color: white;
  font-family: OpenSans;
  font-size: 12px;
  line-height: 17px;
  width: 100%;
}

.btnAppStoreTitleStore {
  color: white;
  font-family: OpenSans;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
}

.btnGoogleStore {
  align-items: center;
  background-color: #909090;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: flex-start;
  margin-top: 14px;
  padding: 0 10px 0 14px;
  width: 200px;
}

.btnGoogleStoreIcon {
  color: white;
  height: 24px;
  width: 24px;
}

.btnGoogleStoreTitle {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}

.btnGoogleStoreTitleText {
  color: white;
  font-family: OpenSans;
  font-size: 12px;
  line-height: 17px;
  width: 100%;
}

.btnGoogleStoreTitleStore {
  color: white;
  font-family: OpenSans;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
}

.btnGoogleStoreSoon {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
}

.btnGoogleStoreSoonBox {
  align-items: center;
  background-color: #183573;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 39px;
  justify-content: center;
  width: 38px;
}

.btnGoogleStoreSoonBoxText {
  color: white;
  font-family: OpenSans-Semibold;
  font-size: 8px;
}

.btnGreen {
  align-items: center;
  background-color: rgba(114, 197, 0, 0.9);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  width: 200px;
}

.btnGreenText {
  color: white;
  font-family: OpenSans-Semibold;
  font-size: 22px;
  line-height: 30px;
}

/* mobile */
.mobileBtnAppStore {
  align-items: center;
  background-color: black;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 42px;
  justify-content: flex-start;
  padding: 0 10px;
  width: 168px;
}

.mobileBtnAppStoreIcon {
  color: white;
  height: 28px;
  width: 28px;
}

.mobileBtnAppStoreTitle {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.mobileBtnAppStoreTitleText {
  color: white;
  font-family: OpenSans;
  font-size: 11px;
  line-height: 14px;
  width: 100%;
}

.mobileBtnAppStoreTitleStore {
  color: white;
  font-family: OpenSans;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
}

.mobileBtnGoogleStore {
  align-items: center;
  background-color: #909090;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 42px;
  justify-content: flex-start;
  padding: 0 5px 0 10px;
  width: 168px;
}

.mobileBtnGoogleStoreIcon {
  color: white;
  height: 20px;
  width: 20px;
}

.mobileBtnGoogleStoreTitle {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.mobileBtnGoogleStoreTitleText {
  color: white;
  font-family: OpenSans;
  font-size: 11px;
  line-height: 14px;
  width: 100%;
}

.mobileBtnGoogleStoreTitleStore {
  color: white;
  font-family: OpenSans;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
}

.mobileBtnGoogleStoreSoonBox {
  align-items: center;
  background-color: #183573;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 32px;
  justify-content: center;
  width: 32px;
}

.mobileBtnGoogleStoreSoonBoxText {
  color: white;
  font-family: OpenSans-Semibold;
  font-size: 7px;
  text-align: center;
}

.mobileBtnGreen {
  align-items: center;
  background-color: rgba(114, 197, 0, 0.9);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 42px;
  justify-content: center;
  width: 200px;
}

.mobileBtnGreenText {
  color: white;
  font-family: OpenSans-Semibold;
  font-size: 14px;
  line-height: 19px;
}
