.contactButton {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
  width: 100%;
}

.contactCheck {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 25px;
  width: 100%;
}

.contactCheckBox {
  align-items: center;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.contactCheckLabel {
  color: white;
  font-family: OpenSans;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
}

.contactCheckMark {
  color: #72C500;
  height: 22px;
  width: 22px;
}

.contactInput {
  border-color: #838383;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  font-family: OpenSans;
  font-size: 14px;
  height: 40px;
  line-height: 19px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.contactText {
  align-items: center;
  background-color: white;
  border-color: #838383;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  height: 145px;
  justify-content: center;
  margin-top: 25px;
  padding: 10px 10px 5px 10px;
  width: 100%;
}

.contactTextArea {
  border: none;
  flex: 1;
  font-family: OpenSans;
  font-size: 14px;
  height: 100%;
  line-height: 19px;
  resize: none;
  width: 100%;
}

.contactTextLimit {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.contactTextLimitText {
  color: #838383;
  font-family: OpenSans;
  font-size: 14px;
}
